import React from "react";
import {Icon} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import {TranslationPortalFile} from "../../../utils/constants";

type ButtonAddPaymentMethodProps = {
    onClick: () => void;
};

const ButtonAddPaymentMethod: React.FC<ButtonAddPaymentMethodProps> = ({onClick}) => {
    const {t: textBillingInfo} = useTranslation(TranslationPortalFile.BILLING_INFO);

    return (
        <div className="action-add-payment-method" onClick={onClick}>
            <Icon name="add_circle" outlined/>
            {textBillingInfo("action.add_payment_method")}
        </div>
    );
};

export default ButtonAddPaymentMethod;
