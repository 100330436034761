export enum BillingPaymentMethodDisplayType {
    CREDIT_CARD = "credit_card",
    IBAN = "iban"
}

export type BillingPaymentMethodDisplay = {
    type: BillingPaymentMethodDisplayType;
    number: string;
    expirationDate?: string;
}
