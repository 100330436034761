import {AlertSeverity, Color, LayoutRows, MainContentPageHeader, MainContentStyle} from "@sirdata/ui-lib";
import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FormChangeBilling} from "../../component/snippet";
import {MainContent, Wrapper} from "../../common/component/widget";
import {MainHeader} from "../../common/component/snippet";
import {pathBillingInfo, searchParamSuccess, TranslationPortalFile} from "../../utils/constants";
import {UIEventManager} from "../../common/utils/UIEventManager";

function BillingInfo() {
    const location = useLocation();
    const navigate = useNavigate();
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textBillingInfo} = useTranslation(TranslationPortalFile.BILLING_INFO);

    useEffect(() => {
        if (location.search.includes(searchParamSuccess)) {
            UIEventManager.alert(t("message.update_success"), AlertSeverity.SUCCESS);
            navigate(pathBillingInfo, {replace: true});
        }
    }, [location.search, navigate, t]);

    return (
        <Wrapper>
            <MainHeader/>
            <MainContent style={MainContentStyle.NARROW_WIDTH}>
                <MainContentPageHeader
                    title={textBillingInfo("title")}
                    icon={{name: "receipt_long", colorIcon: Color.OCEAN, outlined: true}}
                />
                <LayoutRows>
                    <FormChangeBilling/>
                </LayoutRows>
            </MainContent>
        </Wrapper>
    );
}

export default BillingInfo;
