import {ApiModel} from "../../../common/api/model/ApiModel";
import {BillingPaymentMethodDetailsDirectDebit} from "./BillingPaymentMethodDetailsDirectDebit";
import {BillingPaymentMethodDetailsStripe} from "./BillingPaymentMethodDetailsStripe";
import {BillingPaymentMethodDisplay} from "./BillingPaymentMethodDisplay";
import {BillingPaymentMethodMode} from "./BillingPaymentMethodMode";
import {BillingPaymentMethodStatus} from "./BillingPaymentMethodStatus";

export class BillingPaymentMethod extends ApiModel {
    readonly mode: BillingPaymentMethodMode = BillingPaymentMethodMode.BANK_TRANSFER;
    readonly status: BillingPaymentMethodStatus = BillingPaymentMethodStatus.REGISTERED;
    readonly details: any;
    readonly created_at: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any): void {
        if (!o) return;
        super.load(o);
        if (this.mode === BillingPaymentMethodMode.DIRECT_DEBIT) {
            this.loadObject("details", BillingPaymentMethodDetailsDirectDebit);
        } else if (this.mode === BillingPaymentMethodMode.STRIPE) {
            this.loadObject("details", BillingPaymentMethodDetailsStripe);
        }
    }

    display(): BillingPaymentMethodDisplay | undefined {
        switch (this.mode) {
            case BillingPaymentMethodMode.BANK_TRANSFER:
                return;
            case BillingPaymentMethodMode.DIRECT_DEBIT:
                if (this.details instanceof BillingPaymentMethodDetailsDirectDebit) {
                    return this.details.getDisplay();
                }
                break;
            case BillingPaymentMethodMode.STRIPE:
                if (this.details instanceof BillingPaymentMethodDetailsStripe) {
                    return this.details.getDisplay();
                }
                break;
        }
    }
}
