import {ApiModel} from "../../../common/api/model/ApiModel";
import {BillingPaymentMethodDisplay, BillingPaymentMethodDisplayType} from "./BillingPaymentMethodDisplay";

export class BillingPaymentMethodDetailsDirectDebit extends ApiModel {
    direct_debit: {
        country: string;
        last4: string;
    } = {country: "", last4: ""};

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getDisplay(): BillingPaymentMethodDisplay {
        return ({
            type: BillingPaymentMethodDisplayType.IBAN,
            number: `${this.direct_debit.country} ••••••••••••••••••••• ${this.direct_debit.last4}`
        });
    }
}
