import {ApiModel} from "../../../common/api/model/ApiModel";
import {BillingInfoPaymentMethod} from "./BillingInfoPaymentMethod";
import {BillingLanguage} from "./BillingLanguage";
import {BillingPaymentType} from "./BillingPaymentType";

export class BillingInfo extends ApiModel {
    company_name: string = "";
    contact_firstname: string = "";
    contact_lastname: string = "";
    address: string = "";
    postal_code: string = "";
    city: string = "";
    country_iso: string = BillingLanguage.FRENCH;
    vat: string = "";
    iban: string = "";
    bic: string = "";
    business_id: string = "";
    payment_method: BillingInfoPaymentMethod = BillingInfoPaymentMethod.MONTHLY;
    payment_type: BillingPaymentType = BillingPaymentType.DIRECT_DEBIT;
    billing_email: string = "";

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    getJsonParameters(): {} {
        return {
            company_name: this.company_name,
            contact_firstname: this.contact_firstname,
            contact_lastname: this.contact_lastname,
            address: this.address,
            postal_code: this.postal_code,
            city: this.city,
            country_iso: this.country_iso,
            vat: this.vat,
            iban: this.iban,
            bic: this.bic,
            business_id: this.business_id,
            payment_method: this.payment_type === BillingPaymentType.DIRECT_DEBIT ? BillingInfoPaymentMethod.MONTHLY : BillingInfoPaymentMethod.ANNUAL,
            payment_type: this.payment_type,
            billing_email: this.billing_email
        };
    }
}
