import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {AlertSeverity, ButtonLinkCancel, ButtonSave, ButtonSize, FieldBlock, FormLayoutButtons, FormLayoutRows, InputEmail} from "@sirdata/ui-lib";
import {useTranslation} from "react-i18next";
import ButtonUpdate from "../action/ButtonUpdate";
import getApiErrorTranslationKey from "../../../api/model/ApiErrors";
import {BillingInfo} from "../../../api/model/billing/BillingInfo";
import {BillingInfoField} from "../../../api/model/billing/BillingInfoField";
import {ErrorResponse} from "../../../common/api/http/ErrorResponse";
import {session} from "../../../api/ApiSession";
import {TranslationPortalFile} from "../../../utils/constants";
import {UIEventManager} from "../../../common/utils/UIEventManager";

type FormChangeBillingEmailProps = {
    billingInfo: BillingInfo;
    onUpdate: Dispatch<SetStateAction<BillingInfo>>;
};

const FormChangeBillingEmail: React.FC<FormChangeBillingEmailProps> = ({billingInfo: initBillingInfo, onUpdate}) => {
    const {t} = useTranslation(TranslationPortalFile.TRANSLATION);
    const {t: textBillingInfo} = useTranslation(TranslationPortalFile.BILLING_INFO);

    const [billingInfo, setBillingInfo] = useState<BillingInfo>(new BillingInfo());
    const [isSubmitting, setSubmitting] = useState(false);
    const [isEditing, setEditing] = useState(false);

    useEffect(() => {
        setBillingInfo(initBillingInfo);
    }, [initBillingInfo]);

    const handleSubmit = async () => {
        try {
            setSubmitting(true);
            await session.restBilling.updatePartnerBillingInfo(billingInfo);
            onUpdate(billingInfo);
            UIEventManager.alert(t("message.update_success"), AlertSeverity.SUCCESS);
        } catch (e) {
            if (e instanceof ErrorResponse) {
                UIEventManager.alert(t(`error.${getApiErrorTranslationKey(e.message)}`, t("error.default")), AlertSeverity.DANGER);
            }
        } finally {
            setSubmitting(false);
            setEditing(false);
        }
    };

    const handleCancel = () => {
        setBillingInfo(initBillingInfo);
        setEditing(false);
    };

    if (!initBillingInfo) return <></>;

    return (
        <FormLayoutRows>
            <FieldBlock
                name={BillingInfoField.BILLING_EMAIL}
                label={textBillingInfo(`field.${BillingInfoField.BILLING_EMAIL}`)}
                tooltip={textBillingInfo(`tooltip.${BillingInfoField.BILLING_EMAIL}`)}
            >
                <InputEmail
                    placeholder={textBillingInfo(`placeholder.${BillingInfoField.BILLING_EMAIL}`)}
                    value={billingInfo.billing_email}
                    onChange={(value) => setBillingInfo((prevState) => new BillingInfo({...prevState, [BillingInfoField.BILLING_EMAIL]: value}))}
                    disabled={!isEditing}
                />
            </FieldBlock>
            <FormLayoutButtons>
                {isEditing ? (
                    <>
                        <ButtonLinkCancel onClick={handleCancel}/>
                        <ButtonSave
                            size={ButtonSize.MEDIUM}
                            onClick={handleSubmit}
                            disabled={billingInfo.billing_email === initBillingInfo.billing_email}
                            loading={isSubmitting}
                        />
                    </>
                ) : (
                    <ButtonUpdate onClick={() => setEditing(true)}/>
                )}
            </FormLayoutButtons>
        </FormLayoutRows>
    );
};

export default FormChangeBillingEmail;
