import React, {useState} from "react";
import {ButtonSave, FlexContent, FlexContentAlignment, FlexContentDirection, FlexContentSpacing, Loader, ModalActions, ModalContent, ModalNew, ModalTitle, Paragraph} from "@sirdata/ui-lib";
import {Elements as StripeProvider} from "@stripe/react-stripe-js";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {PaymentMethodForm} from "../widget";
import {PAYMENT_METHOD_FORM_ID} from "../widget/PaymentMethodForm";
import {stripePromise, stripeProviderOptions} from "../../utils/stripe";
import {TranslationPortalFile} from "../../utils/constants";

type ModalAddPaymentMethodProps = {
    active: boolean;
    title: string;
    onClose: () => void;
};

const PENDING_TIME = 15000;

const ModalAddPaymentMethod: React.FC<ModalAddPaymentMethodProps> = ({active, title, onClose}) => {
    const navigate = useNavigate();
    const {t: textBillingInfo} = useTranslation(TranslationPortalFile.BILLING_INFO);

    const [isSubmitting, setSubmitting] = useState(false);
    const [isSucceeded, setSucceeded] = useState(false);
    const [time, setTime] = useState(PENDING_TIME / 1000);

    const handleSuccess = () => {
        setSucceeded(true);
        setInterval(() => setTime((prev) => prev - 1), 1000);
        setTimeout(() => navigate(0), PENDING_TIME);
    };

    return (
        <ModalNew onClose={isSucceeded ? undefined : onClose} active={active}>
            {isSucceeded ? (
                <ModalContent>
                    <FlexContent direction={FlexContentDirection.COLUMN} alignment={FlexContentAlignment.CENTER} spacing={FlexContentSpacing.MEDIUM}>
                        <Loader small/>
                        <span className="h2">{textBillingInfo("modal.add_payment_method_success.title")}</span>
                        <Paragraph>{textBillingInfo("modal.add_payment_method_success.description", {time})}</Paragraph>
                    </FlexContent>
                </ModalContent>
            ) : (
                <>
                    <ModalContent>
                        <ModalTitle>{title}</ModalTitle>
                        <StripeProvider stripe={stripePromise} options={stripeProviderOptions}>
                            <PaymentMethodForm onSubmit={setSubmitting} onSuccess={handleSuccess}/>
                        </StripeProvider>
                    </ModalContent>
                    <ModalActions>
                        <ButtonSave form={PAYMENT_METHOD_FORM_ID} loading={isSubmitting}/>
                    </ModalActions>
                </>
            )}
        </ModalNew>
    );
};

export default ModalAddPaymentMethod;
