import {Button, ButtonStyle, FieldBlock, FormLayoutButtons, FormLayoutColumns, Tag, TagStyle} from "@sirdata/ui-lib";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {BillingPaymentMethod} from "../../api/model/billing/BillingPaymentMethod";
import {BillingPaymentMethodDisplay, BillingPaymentMethodDisplayType} from "../../api/model/billing/BillingPaymentMethodDisplay";
import {BillingPaymentMethodMode} from "../../api/model/billing/BillingPaymentMethodMode";
import {TranslationPortalFile} from "../../utils/constants";

type PaymentMethodDisplayProps = {
    paymentMethod: BillingPaymentMethod;
    onChange: () => void;
}

const PaymentMethodDisplay: React.FC<PaymentMethodDisplayProps> = ({paymentMethod, onChange}) => {
    const {t: textBillingInfo} = useTranslation(TranslationPortalFile.BILLING_INFO);
    const [paymentMethodDisplay, setPaymentMethodDisplay] = useState<BillingPaymentMethodDisplay>();

    useEffect(() => {
        setPaymentMethodDisplay(paymentMethod.display());
    }, [paymentMethod]);

    const buildTag = (paymentMethod: BillingPaymentMethod) => {
        if (paymentMethod.mode === BillingPaymentMethodMode.STRIPE && paymentMethodDisplay?.type === BillingPaymentMethodDisplayType.CREDIT_CARD) {
            return (
                <Tag
                    label={textBillingInfo("label.credit_card")}
                    icon={{name: "credit_card", outlined: true}}
                    style={TagStyle.DEFAULT_OCEAN}
                />
            );
        }

        if (paymentMethod.mode === BillingPaymentMethodMode.BANK_TRANSFER) {
            return (
                <Tag
                    label={textBillingInfo("label.bank_transfer")}
                    icon={{name: "account_balance", outlined: true}}
                    style={TagStyle.DEFAULT_OCEAN}
                />
            );
        }

        return (
            <Tag
                label={textBillingInfo("label.sepa_debit")}
                icon={{name: "account_balance", outlined: true}}
                style={TagStyle.DEFAULT_OCEAN}
            />
        );
    };

    return (
        <>
            <div>{buildTag(paymentMethod)}</div>
            {paymentMethodDisplay?.type === BillingPaymentMethodDisplayType.CREDIT_CARD && (
                <FormLayoutColumns>
                    <FieldBlock label={textBillingInfo("label.card_number")}>
                        {paymentMethodDisplay.number}
                    </FieldBlock>
                    <FieldBlock label={textBillingInfo("label.expiration_date")}>
                        {paymentMethodDisplay.expirationDate}
                    </FieldBlock>
                </FormLayoutColumns>
            )}
            {paymentMethodDisplay?.type === BillingPaymentMethodDisplayType.IBAN && (
                <FieldBlock label={textBillingInfo("label.iban")}>
                    {paymentMethodDisplay.number}
                </FieldBlock>
            )}
            <FormLayoutButtons>
                <Button onClick={onChange} style={ButtonStyle.PRIMARY_MIDNIGHT}>
                    {textBillingInfo("action.change_payment_method")}
                </Button>
            </FormLayoutButtons>
        </>
    );
};

export default PaymentMethodDisplay;
