import {BillingInfo} from "../model/billing/BillingInfo";
import {BillingPaymentMethod} from "../model/billing/BillingPaymentMethod";
import {Invoice} from "../model/billing/Invoice";
import {MonthlyInvoices} from "../model/billing/MonthlyInvoices";
import {Rest} from "../../common/api/rest/Rest";
import {SetupPaymentMethodStripe} from "../model/billing/SetupPaymentMethodStripe";

export class RestBilling extends Rest {
    rootPath = "/partner";

    getInvoices(start: string, end: string): Promise<MonthlyInvoices[]> {
        return this._client.get(`${this.rootPath}/invoice?start=${start}&end=${end}`, MonthlyInvoices) as Promise<MonthlyInvoices[]>;
    }

    getInvoiceFile(invoice: Invoice): Promise<Blob> { // TODO Check file type
        return this._client.get(`${this.rootPath}/invoice/${invoice.id}/origin/${invoice.origin}`, undefined, {responseType: "blob"}) as Promise<Blob>;
    }

    getPartnerBillingInfo(): Promise<BillingInfo> {
        return this._client.get(`${this.rootPath}/billing-info`, BillingInfo) as Promise<BillingInfo>;
    }

    updatePartnerBillingInfo(billingInfo: BillingInfo): Promise<BillingInfo> {
        return this._client.post(`${this.rootPath}/billing-info`, billingInfo, BillingInfo) as Promise<BillingInfo>;
    }

    getPaymentMethods(): Promise<BillingPaymentMethod[]> {
        return this._client.get(`${this.rootPath}/payment-method`, BillingPaymentMethod) as Promise<BillingPaymentMethod[]>;
    }

    setupPaymentMethodStripe(): Promise<SetupPaymentMethodStripe> {
        return this._client.post(`${this.rootPath}/payment-method/stripe/setup-intent`) as Promise<SetupPaymentMethodStripe>;
    }
}
