import {ApiModel} from "../../../common/api/model/ApiModel";
import {BillingPaymentMethodDisplay, BillingPaymentMethodDisplayType} from "./BillingPaymentMethodDisplay";
import moment from "moment/moment";

export enum BillingPaymentMethodDetailsStripeType {
    CARD = "card",
    SEPA_DEBIT = "sepa_debit"
}

export class BillingPaymentMethodStripeDetailsStripeCard extends ApiModel {
    readonly type: BillingPaymentMethodDetailsStripeType = BillingPaymentMethodDetailsStripeType.CARD;
    readonly card: {
        brand: string;
        last4: string;
        exp_month: number;
        exp_year: number;
    } = {brand: "", last4: "", exp_month: 0, exp_year: 0};

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}

export class BillingPaymentMethodStripeDetailsStripeSepaDebit extends ApiModel {
    readonly type: BillingPaymentMethodDetailsStripeType = BillingPaymentMethodDetailsStripeType.SEPA_DEBIT;
    readonly sepa_debit: {
        country: string;
        last4: string;
    } = {country: "", last4: ""};

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }
}

export class BillingPaymentMethodDetailsStripe extends ApiModel {
    stripe: BillingPaymentMethodStripeDetailsStripeCard | BillingPaymentMethodStripeDetailsStripeSepaDebit = new BillingPaymentMethodStripeDetailsStripeCard();

    constructor(o?: {}) {
        super(o);
        this.load(o);
    }

    load(o: any) {
        if (!o) return;
        super.load(o);
        if (this.stripe.type === BillingPaymentMethodDetailsStripeType.SEPA_DEBIT) {
            this.loadObject("stripe", BillingPaymentMethodStripeDetailsStripeSepaDebit);
        } else {
            this.loadObject("stripe", BillingPaymentMethodStripeDetailsStripeCard);
        }
    }

    getDisplay(): BillingPaymentMethodDisplay {
        if (this.stripe instanceof BillingPaymentMethodStripeDetailsStripeSepaDebit) {
            return ({
                type: BillingPaymentMethodDisplayType.IBAN,
                number: `${this.stripe.sepa_debit.country} ••••••••••••••••••••• ${this.stripe.sepa_debit.last4}`
            });
        }

        return ({
            type: BillingPaymentMethodDisplayType.CREDIT_CARD,
            number: `•••• •••• •••• ${this.stripe.card.last4}`,
            expirationDate: moment()
                .set({"month": (this.stripe.card.exp_month - 1), "year": this.stripe.card.exp_year})
                .format("MM / YY")
        });
    }
}
