import React, {useEffect, useState} from "react";
import {
    Box,
    BoxSpacing,
    Button,
    ButtonSave,
    ButtonSize,
    ButtonStyle,
    ContentBlock,
    FlexContent,
    FlexContentAlignment,
    FlexContentDirection,
    FlexContentLayout,
    FlexContentMobileDirection,
    FlexContentSpacing,
    FormLayoutButtons,
    FormLayoutRows,
    Loadable
} from "@sirdata/ui-lib";
import {Elements as StripeProvider} from "@stripe/react-stripe-js";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

import useRegister from "../../utils/hooks/useRegister";
import {LicensedProduct} from "../../api/model/account/LicensedProduct";
import {LicenseName} from "../../api/model/account/LicenseName";
import {LogoSirdataOnboarding, TrialInformation} from "../../component/snippet";
import {pathRegister, pathRegisterPayment, searchParamSuccess, TranslationPortalFile} from "../../utils/constants";
import {PaymentMethodForm} from "../../component/widget";
import {PAYMENT_METHOD_FORM_ID} from "../../component/widget/PaymentMethodForm";
import {session} from "../../api/ApiSession";
import {stripePromise, stripeProviderOptions} from "../../utils/stripe";

function RegisterPayment() {
    const location = useLocation();
    const navigate = useNavigate();
    const {t: textOnboarding} = useTranslation(TranslationPortalFile.ONBOARDING);
    const [registerer, {goToNext}] = useRegister(pathRegisterPayment);

    const [isSubmitting, setSubmitting] = useState(false);
    const [isShowPaymentInfo, setShowPaymentInfo] = useState(false);

    useEffect(() => {
        if (!registerer.isOriginStepAllowed) {
            navigate(pathRegister, {replace: true});
        } else {
            (async function () {
                try {
                    const partnerLicenses = await session.restLicense.list();
                    const activePartnerLicenseNames = partnerLicenses.filter((it) => it.active).map((it) => it.license.name as LicenseName);
                    const isShowPaymentInfo = activePartnerLicenseNames.some((name) => LicensedProduct.getLicenseByName(name)?.isPaymentInfoRequired);
                    if (!isShowPaymentInfo || location.search.includes(searchParamSuccess)) {
                        goToNext();
                    }
                    setShowPaymentInfo(isShowPaymentInfo);
                } catch (e) {
                }
            })();
        }
    }, [navigate, registerer.isOriginStepAllowed, goToNext, location.search]);

    return (
        <div className="wrapper onboarding">
            <div className="register-page">
                <div className="register-form register-form--large">
                    <div className="main-logo">
                        <LogoSirdataOnboarding/>
                    </div>
                    <div className="h1 title" dangerouslySetInnerHTML={{__html: textOnboarding("register_payment.title", {step: session.onboarding.getCurrentStep(pathRegisterPayment), steps: session.onboarding.getTotalSteps()})}}/>
                    <div className="description">{textOnboarding("register_payment.description")}</div>
                    <ContentBlock cssClass="register-form__block">
                        <Loadable loading={!isShowPaymentInfo}>
                            <Box spacing={BoxSpacing.XLARGE}>
                                <FormLayoutRows>
                                    <FlexContent
                                        direction={FlexContentDirection.ROW}
                                        spacing={FlexContentSpacing.XLARGE}
                                        layout={FlexContentLayout.TWO_COLUMNS_WIDE_LEFT}
                                        mobileDirection={FlexContentMobileDirection.COLUMN}
                                    >
                                        <StripeProvider stripe={stripePromise} options={stripeProviderOptions}>
                                            <PaymentMethodForm onSubmit={setSubmitting} onSuccess={goToNext}/>
                                        </StripeProvider>
                                        <TrialInformation/>
                                    </FlexContent>
                                    <FlexContent direction={FlexContentDirection.COLUMN} spacing={FlexContentSpacing.XSMALL} alignment={FlexContentAlignment.CENTER}>
                                        <FormLayoutButtons>
                                            <Button size={ButtonSize.BIG} style={ButtonStyle.DEFAULT_MIDNIGHT} onClick={() => navigate(session.onboarding.getPrevStepPath(pathRegisterPayment))}>{textOnboarding("form.button.previous")}</Button>
                                            {session.onboarding.isStepSkippable(pathRegisterPayment) &&
                                                <Button size={ButtonSize.BIG} style={ButtonStyle.DEFAULT_MIDNIGHT} onClick={goToNext}>{textOnboarding("form.button.complete_later")}</Button>
                                            }
                                            <ButtonSave form={PAYMENT_METHOD_FORM_ID} loading={isSubmitting}/>
                                        </FormLayoutButtons>
                                    </FlexContent>
                                </FormLayoutRows>
                            </Box>
                        </Loadable>
                    </ContentBlock>
                </div>
            </div>
        </div>
    );
}

export default RegisterPayment;
